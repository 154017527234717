import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Http from '@ftrm/generic-http-service'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    cursor: 'pointer'
  },
  createButton: {
    position: 'fixed',
    right: theme.spacing.unit * 2,
    bottom: theme.spacing.unit * 2
  }
})

@inject('store')
@observer
class OrganisationList extends Component {
  constructor (props) {
    super(props)

    this._http = new Http(process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital')
  }

  async componentWillMount () {
    this.props.store.generic.set('isLoading', true)
    const response = await this._http.get('/v1/organisations', this.props.store.user.token)

    if (response.data && Array.isArray(response.data)) {
      this.props.store.organisationList.set(response.data)
    }

    this.props.store.generic.set('isLoading', false)
  }

  createNew = (store) => {
    store.organisationForm.resetOrganisation()
    store.generic.set('showOrganisationForm', true)
  }

  render () {
    const { store, classes } = this.props
    const { organisationList } = store.organisationList

    return (
      <div>
        <Typography className={classes.title} variant='h6' color='inherit' noWrap>
          Organisations
        </Typography>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                organisationList.map((organisation, index) => (
                  <TableRow className={classes.tableRow} key={index} onClick={() => {
                    store.organisationForm.setOrganisation({
                      name: organisation.name,
                      _id: organisation._id
                    })
                    store.generic.set('showOrganisationForm', true)
                  }}>
                    <TableCell component='th' scope='row'>{organisation.name}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Paper>
        <Fab color='primary' className={classes.createButton} aria-label='add' onClick={() => this.createNew(store)}>
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

export default withStyles(styles)(OrganisationList)
