import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginScreen from '@ftrm/react-login-screen'
import Http from '@ftrm/generic-http-service'
import DashboardScreen from './screens/Dashboard'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ToastBar from '@ftrm/react-toast-bar'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#14366D'
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
})

@inject('store')
@observer
class App extends Component {
  constructor (props) {
    super(props)

    this._http = new Http(process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital')

    const token = window.localStorage.getItem('token')
    const admin = window.localStorage.getItem('admin')
    const slug = window.localStorage.getItem('slug')
    if (token) {
      this.props.store.user.set('token', token)
    }
    if (admin) {
      this.props.store.user.set('admin', admin === 'true')
    }
    if (slug) {
      this.props.store.user.set('slug', slug)
    }

    const url = new URL(window.location.href)
    const referralCode = url.searchParams.get('register')

    if (referralCode) {
      this.props.store.user.set('referralCode', referralCode)
    }

    const resetCode = url.searchParams.get('password-reset')

    if (resetCode) {
      this.props.store.user.set('resetCode', resetCode)
    }
  }

  async componentWillMount () {
    if (this.props.store.user.referralCode) {
      let response = await this._http.get(`/v1/users/referralCode/${this.props.store.user.referralCode}`)

      if (response.data) {
        if (response.data.name) {
          this.props.store.user.set('name', response.data.name)
        }
        if (response.data.email) {
          this.props.store.user.set('email', response.data.email)
        }
      } else {
        this.props.store.generic.set('errorMessage', response.message)
        // window.location.href = window.location.origin
      }
    }
  }

  render () {
    const { store } = this.props
    const { email, password, token, name, referralCode, resetCode, confirmPassword } = store.user
    const { isLoading, successMessage, errorMessage } = store.generic

    return (
      <MuiThemeProvider theme={theme}>
        <div id='app-root' style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          {
            token
              ? <DashboardScreen />
              : <LoginScreen
                language='en-US'
                title='Relocation Service'
                email={email}
                password={password}
                logoUrl='/img/logo_black_en.png'
                boxBackgroundColor='red'
                backgroundColor='#fff'
                containerBackgroundColor='#eee'
                textColor='#14366D'
                buttonBackgroundColor='#14366D'
                buttonTextColor='#fff'
                errorMessage={''}
                successMessage={''}
                isLoading={isLoading}
                onEmailChange={(event) => store.user.set('email', event.target.value)}
                onPasswordChange={(event) => store.user.set('password', event.target.value)}
                onLogin={async () => {
                  store.generic.set('isLoading', true)
                  try {
                    const response = await this._http.post('/v1/users/login', JSON.stringify({ email, password }))

                    if (response.status >= 400) {
                      store.generic.set('errorMessage', response.message)
                    } else {
                      store.user.set('token', response.data.token)
                      store.user.set('admin', response.data.admin)
                      store.user.set('slug', response.data.slug)
                      window.localStorage.setItem('token', response.data.token)
                      window.localStorage.setItem('admin', response.data.admin)
                      window.localStorage.setItem('slug', response.data.slug)
                    }
                  } catch (e) {
                    console.log(e)
                  }
                  store.generic.set('isLoading', false)
                }}
                onPasswordReset={async () => {
                  store.generic.set('isLoading', true)
                  try {
                    const response = await this._http.post('/v1/users/password-reset', JSON.stringify({ email }))

                    if (response.status >= 400) {
                      store.generic.set('errorMessage', response.message)
                    } else {
                      store.generic.set('successMessage', 'If you have an account, you will recieve an email soon')
                    }
                  } catch (e) {
                    console.log(e)
                  }
                  store.generic.set('isLoading', false)
                }}
                referralCode={referralCode}
                resetCode={resetCode}
                name={name}
                onNameChange={(event) => store.user.set('name', event.target.value)}
                onConfirmPasswordChange={(event) => store.user.set('confirmPassword', event.target.value)}
                onRegister={async () => {
                  store.generic.set('isLoading', true)
                  try {
                    const response = await this._http.post('/v1/users/register', JSON.stringify({ name, password, referralCode }))

                    if (response.status >= 400) {
                      store.generic.set('errorMessage', response.message)
                    } else {
                      store.generic.set('successMessage', 'You are now registered')
                      setTimeout(() => {
                        window.location.href = window.location.origin
                      }, 4000)
                    }
                  } catch (e) {
                    console.log(e)
                  }
                  store.generic.set('isLoading', false)
                }}
                onPasswordResetSubmit={async () => {
                  store.generic.set('isLoading', true)
                  try {
                    if (confirmPassword !== password) {
                      store.generic.set('errorMessage', 'The passwords do not match')
                    } else {
                      const response = await this._http.put('/v1/users/password-reset', JSON.stringify({ password, resetCode }))

                      if (response.status >= 400) {
                        store.generic.set('errorMessage', 'Invalid request, please request a new password reset')
                      } else {
                        store.generic.set('successMessage', 'Password change successful')
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                  store.generic.set('isLoading', false)
                }}
              />
          }
          <ToastBar
            successMessage={successMessage}
            errorMessage={errorMessage}
            isLoading={isLoading}
            primaryColor={theme.palette.primary.main}
            messageTimeSpan={5000}
          />
        </div>
      </MuiThemeProvider>
    )
  }
}

export default App
