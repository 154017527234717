import { action, observable, computed } from 'mobx'

class CityListStore {
  @observable cities = []

  @action set (val) {
    this.cities = val
  }

  @computed get cityList () {
    return this.cities
  }
}

export default CityListStore
