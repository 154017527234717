import { action, observable, computed } from 'mobx'

class UserStore {
  @observable user = {
    token: '',
    email: '',
    password: '',
    newPassword: '',
    confirmPassword: '',
    slug: '',
    admin: false,
    referralCode: '',
    resetCode: '',
    name: ''
  }

  @action set (key, val) {
    this.user[key] = val
  }

  @computed get email () {
    return this.user.email
  }

  @computed get slug () {
    return this.user.slug
  }

  @computed get name () {
    return this.user.name
  }

  @computed get referralCode () {
    return this.user.referralCode
  }

  @computed get resetCode () {
    return this.user.resetCode
  }

  @computed get admin () {
    return this.user.admin
  }

  @computed get password () {
    return this.user.password
  }

  @computed get newPassword () {
    return this.user.newPassword
  }

  @computed get confirmPassword () {
    return this.user.confirmPassword
  }

  @computed get token () {
    return this.user.token
  }
}

export default UserStore
