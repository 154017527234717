import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import store from './mobx/stores/AppStore'
import AppRouter from './router/AppRouter'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <AppRouter>
      <App />
    </AppRouter>
  </Provider>
  , document.getElementById('root'))

serviceWorker.unregister()
