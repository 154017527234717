import { action, observable, computed } from 'mobx'

class OrganisationListStore {
  @observable organisations = []

  @action set (val) {
    this.organisations = val
  }

  @computed get organisationList () {
    return this.organisations
  }
}

export default OrganisationListStore
