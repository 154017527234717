import { action, observable, computed } from 'mobx'

class UserListStore {
  @observable users = []

  @action set (val) {
    this.users = val
  }

  @computed get userList () {
    return this.users
  }
}

export default UserListStore
