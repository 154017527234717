import { action, observable, computed } from 'mobx'

class UserStore {
  @observable user = {
    name: '',
    email: '',
    organisation: '',
    organisationId: '',
    admin: false,
    city: {
      slug: '',
      name: '',
      _id: ''
    },
    _id: ''
  }

  @action set (key, val) {
    this.user[key] = val
  }

  @action resetUser () {
    this.user = {
      name: '',
      email: '',
      organisation: '',
      organisationId: '',
      admin: false,
      city: {
        slug: '',
        name: '',
        _id: ''
      },
      _id: ''
    }
  }

  @action setUser (val) {
    this.resetUser()
    this.user = val
  }

  @computed get email () {
    return this.user.email
  }

  @computed get organisation () {
    return this.user.organisation
  }

  @computed get organisationId () {
    return this.user.organisationId
  }

  @computed get admin () {
    return this.user.admin
  }

  @computed get _id () {
    return this.user._id
  }

  @computed get name () {
    return this.user.name
  }

  @computed get city () {
    return this.user.city
  }
}

export default UserStore
