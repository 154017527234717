import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Http from '@ftrm/generic-http-service'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import UserForm from '../components/UserForm'
import OrganisationForm from '../components/OrganisationForm'
import UserList from '../components/UserList'
import OrganisationList from '../components/OrganisationList'

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  logo: {
    width: 75,
    padding: 5
  },
  card: {
    flex: 1,
    borderRadius: 0
  },
  media: {
    height: '100%'
  },
  container: {
    margin: 16
  },
  dashboardContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
})

@inject('store')
@observer
class Dashboard extends Component {
  constructor (props) {
    super(props)

    this._http = new Http(process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital')
  }

  logout = (store) => {
    store.user.set('token', '')
    window.localStorage.setItem('token', '')
    store.user.set('admin', false)
    window.localStorage.setItem('admin', false)
    store.user.set('slug', '')
    window.localStorage.setItem('slug', '')
  }

  handleShowEditProfile = (store, showEditProfile) => {
    store.generic.set('showEditProfile', !showEditProfile)
    store.user.set('password', '')
    store.user.set('newPassword', '')
    store.user.set('confirmPassword', '')
  }

  handleShowOrganisationList = (store) => {
    store.generic.set('showOrganisationList', true)
    store.generic.set('showUserList', false)
  }

  handleShowUserList = (store) => {
    store.generic.set('showUserList', true)
    store.generic.set('showOrganisationList', false)
  }

  render () {
    const { store, classes } = this.props
    const { password, newPassword, confirmPassword, token, admin, slug } = store.user
    const {
      showEditProfile,
      showUserForm,
      showOrganisationForm,
      showOrganisationList,
      showUserList
    } = store.generic

    return (
      <div className={classes.dashboardContainer}>
        <AppBar position='static'>
          <Toolbar>
            <img alt='logo' src='/img/logo_en.png' className={classes.logo} />
            <Typography className={classes.title} variant='h6' color='inherit' noWrap>
              Relocation Service
            </Typography>
            <div className={classes.grow} />
            {
              admin
                ? <div>
                  <IconButton color='inherit' onClick={() => this.handleShowOrganisationList(store)}>
                    <CardTravelIcon />
                  </IconButton>
                  <IconButton color='inherit' onClick={() => this.handleShowUserList(store)}>
                    <AccessibilityNewIcon />
                  </IconButton>
                </div>
                : <IconButton color='inherit' onClick={() => this.handleShowEditProfile(store, showEditProfile)}>
                  <SettingsIcon />
                </IconButton>
            }
            <Button color='inherit' onClick={() => this.logout(store)}>Logout</Button>
          </Toolbar>
          <Dialog open={this.props.store.generic.showEditProfile} onClose={() => this.handleShowEditProfile(store, showEditProfile)} aria-labelledby='form-dialog-title'>
            <form onSubmit={async (event) => {
              event.preventDefault()
              const response = await this._http.put('/v1/users', JSON.stringify({ oldPassword: password, newPassword }), token)

              if (response.status === 401) {
                this.logout(store)
              } else if (response.status >= 400) {
                store.generic.set('errorMessage', 'Failed to update user')
              } else {
                store.generic.set('successMessage', 'User is now updated')
              }

              this.handleShowEditProfile(store, showEditProfile)
            }}>
              <DialogTitle id='form-dialog-title'>Update user</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin='dense'
                  id='password'
                  label='Password'
                  type='password'
                  name='password'
                  value={password}
                  fullWidth
                  onChange={(event) => store.user.set(event.target.name, event.target.value)}
                />
                <TextField
                  autoFocus
                  margin='dense'
                  id='new-password'
                  label='New password'
                  type='password'
                  name='newPassword'
                  value={newPassword}
                  fullWidth
                  onChange={(event) => store.user.set(event.target.name, event.target.value)}
                />
                <TextField
                  autoFocus
                  margin='dense'
                  id='confirm-new-password'
                  label='Confirm new password'
                  type='password'
                  name='confirmPassword'
                  value={confirmPassword}
                  fullWidth
                  onChange={(event) => store.user.set(event.target.name, event.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleShowEditProfile(store, showEditProfile)} color='primary'>
                  Cancel
                </Button>
                <Button
                  color='primary'
                  type='submit'
                >
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </AppBar>
        {
          admin
            ? <div className={classes.container}>
              {
                showUserForm
                  ? <UserForm />
                  : null
              }
              {
                showOrganisationForm
                  ? <OrganisationForm />
                  : null
              }
              {
                showOrganisationList
                  ? <OrganisationList />
                  : null
              }
              {
                showUserList
                  ? <UserList />
                  : null
              }
            </div>
            : <Card className={classes.card}>
              <CardMedia
                component='iframe'
                media='iframe'
                className={classes.media}
                src={`${process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital'}/web/viewer.html?file=${slug}.pdf`}
              />
            </Card>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Dashboard)
