import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Http from '@ftrm/generic-http-service'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  formParent: {
    flexDirection: 'column',
    display: 'flex',
    minWidth: 300
  },
  formElement: {
    flex: 1
  },
  wrapper: {
    margin: 0
  },
  formButtonContainer: {
    displat: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0
  },
  formButton: {
    margin: 0
  },
  formDeleteButton: {
    width: 48,
    alignSelf: 'end'
  }
})

@inject('store')
@observer
class UserForm extends Component {
  constructor (props) {
    super(props)

    this._http = new Http(process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital')
  }

  logout = (store) => {
    store.user.set('token', '')
    window.localStorage.setItem('token', '')
    store.user.set('admin', false)
    window.localStorage.setItem('admin', false)
    store.user.set('slug', '')
    window.localStorage.setItem('slug', '')
  }

  handleChange = (event) => {
    this.props.store.userForm.set(event.target.name, event.target.value)
  }

  handleChangeCheckbox = (event) => {
    this.props.store.userForm.set(event.target.name, event.target.checked)
  }

  resetFormUpdateList = async (store) => {
    this.props.store.generic.set('isLoading', true)
    this.handleClose(store)
    store.userForm.resetUser()

    let response = await this._http.get('/v1/users', store.user.token)

    if (response.data && Array.isArray(response.data)) {
      const userList = []
      response.data.forEach((userData) => {
        userList.push({
          name: userData.name,
          email: userData.email,
          admin: userData.admin,
          organisation: userData.organisation ? userData.organisation.name : '',
          organisationId: userData.organisation ? userData.organisation._id : '',
          _id: userData._id,
          activated: userData.activated,
          city: userData.city
        })
      })

      store.userList.set(userList)
    }
    this.props.store.generic.set('isLoading', false)
  }

  submit = async (store) => {
    this.props.store.generic.set('isLoading', true)
    let response
    if (store.userForm.user._id) {
      response = await this._http.put(`/v1/users/${store.userForm.user._id}`, JSON.stringify({
        email: store.userForm.user.email,
        organisation: store.userForm.user.organisationId,
        name: store.userForm.user.name,
        admin: store.userForm.user.admin,
        city: store.userForm.user.city
      }), store.user.token)
    } else {
      response = await this._http.post(`/v1/organisations/referral/${store.userForm.admin ? 'admin' : 'user'}`, JSON.stringify({
        email: store.userForm.user.email,
        organisation: store.userForm.user.organisationId,
        name: store.userForm.user.name,
        admin: store.userForm.user.admin,
        city: store.userForm.user.city
      }), store.user.token)
    }

    if (response.status === 401) {
      this.logout(store)
    }

    if (response.message) {
      if (response.status >= 400) {
        store.generic.set('errorMessage', response.message)
      } else {
        store.generic.set('successMessage', response.message)
      }
    } else {
      store.generic.set('successMessage', 'User updated')
    }
    store.generic.set('successMessage', '')
    store.generic.set('errorMessage', '')

    this.resetFormUpdateList(store)
  }

  delete = async (store) => {
    this.props.store.generic.set('isLoading', true)
    if (store.userForm._id) {
      let response = await this._http.del(`/v1/users/${store.userForm._id}`, '', store.user.token)

      if (response.status === 401) {
        this.logout(store)
      }

      if (response.message) {
        if (response.status >= 400) {
          store.generic.set('errorMessage', response.message)
        } else {
          store.generic.set('successMessage', response.message)
        }
      } else {
        store.generic.set('successMessage', 'User deleted')
      }
      store.generic.set('successMessage', '')
      store.generic.set('errorMessage', '')

      this.resetFormUpdateList(store)
    }
    this.props.store.generic.set('isLoading', false)
  }

  handleClose = async (store) => {
    store.generic.set('showUserForm', false)
  }

  render () {
    const { store, classes } = this.props
    const { name, email, admin, organisationId, _id, city } = store.userForm
    const { organisationList } = store.organisationList
    const { cityList } = store.cityList
    const { showUserForm } = store.generic

    return (
      <Dialog
        open={showUserForm}
        onClose={() => this.handleClose(store)}
        PaperProps={{ className: classes.wrapper }}>
        <form className={classes.container} noValidate autoComplete='off' onSubmit={(event) => {
          event.preventDefault()
          this.submit(store)
        }} >
          <DialogContent className={classes.formParent}>
            {
              _id
                ? <IconButton className={classes.formDeleteButton} color='inherit' onClick={() => this.delete(store)}>
                  <DeleteIcon />
                </IconButton>
                : null
            }
            <TextField
              className={classes.formElement}
              id='name'
              name='name'
              label='Name'
              value={name}
              onChange={this.handleChange}
              margin='normal'
              autoFocus
            />
            <TextField
              className={classes.formElement}
              id='email'
              name='email'
              label='Email'
              value={email}
              onChange={this.handleChange}
              margin='normal'
            />
            <FormControl
              margin='normal'
              className={classes.formElement}>
              <InputLabel htmlFor='organisation'>Organisation</InputLabel>
              <Select
                value={organisationId}
                onChange={this.handleChange}
                inputProps={{
                  id: 'organisation',
                  name: 'organisationId',
                  label: 'Organisation'
                }}
                autoWidth
              >
                {
                  organisationList.map((org, ind) => (
                    <MenuItem key={ind} value={org._id}>{org.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl
              margin='normal'
              className={classes.formElement}>
              <InputLabel htmlFor='city'>City</InputLabel>
              <Select
                value={city}
                onChange={this.handleChange}
                inputProps={{
                  id: 'city',
                  name: 'city',
                  label: 'city'
                }}
                autoWidth
              >
                {
                  cityList.map((city, ind) => (
                    <MenuItem key={ind} value={city._id}>{city.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.formElement}
              control={
                <Checkbox
                  id='admin'
                  name='admin'
                  checked={admin}
                  onChange={this.handleChangeCheckbox}
                  value='admin'
                  color='primary'
                />
              }
              label='Admin'
            />
            <DialogActions className={classes.formButtonContainer}>
              <Button
                className={classes.formButton}
                onClick={() => this.handleClose(store)} color='inherit'>
                Cancel
              </Button>
              <Button
                className={classes.formButton}
                type='submit'
                variant='contained'
                color='primary'
                onClick={(event) => {
                  event.preventDefault()
                  this.submit(store)
                }}>
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UserForm)
