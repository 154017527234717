import UserStore from './UserStore'
import UserFormStore from './UserFormStore'
import UserListStore from './UserListStore'
import OrganisationFormStore from './OrganisationFormStore'
import OrganisationListStore from './OrganisationListStore'
import CityListStore from './CityListStore'
import GenericStore from './GenericStore'

const store = {
  user: new UserStore(),
  generic: new GenericStore(),
  userForm: new UserFormStore(),
  organisationForm: new OrganisationFormStore(),
  userList: new UserListStore(),
  organisationList: new OrganisationListStore(),
  cityList: new CityListStore()
}

export default store
