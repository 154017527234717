import { action, observable, computed } from 'mobx'

class GenericStore {
  @observable generic = {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    showEditProfile: false,
    showUserForm: false,
    showOrganisationForm: false,
    showOrganisationList: true,
    showUserList: false
  }

  @action set (key, val) {
    this.generic[key] = val
  }

  @computed get isLoading () {
    return this.generic.isLoading
  }

  @computed get errorMessage () {
    return this.generic.errorMessage
  }

  @computed get successMessage () {
    return this.generic.successMessage
  }

  @computed get showEditProfile () {
    return this.generic.showEditProfile
  }

  @computed get showUserForm () {
    return this.generic.showUserForm
  }

  @computed get showUserList () {
    return this.generic.showUserList
  }

  @computed get showOrganisationForm () {
    return this.generic.showOrganisationForm
  }

  @computed get showOrganisationList () {
    return this.generic.showOrganisationList
  }
}

export default GenericStore
