import { action, observable, computed } from 'mobx'

class OrganisationForm {
  @observable organisation = {
    name: '',
    address: '',
    city: '',
    postalCode: '',
    _id: ''
  }

  @action set (key, val) {
    this.organisation[key] = val
  }

  @action resetOrganisation (val) {
    this.organisation = {
      name: '',
      address: '',
      city: '',
      postalCode: '',
      _id: ''
    }
  }

  @action setOrganisation (val) {
    this.resetOrganisation()
    this.organisation = val
  }

  @computed get address () {
    return this.organisation.address
  }

  @computed get postalCode () {
    return this.organisation.postalCode
  }

  @computed get city () {
    return this.organisation.city
  }

  @computed get _id () {
    return this.organisation._id
  }

  @computed get name () {
    return this.organisation.name
  }
}

export default OrganisationForm
