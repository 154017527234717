import React from 'react'
import App from '../App'
import LoginScreen from '@ftrm/react-login-screen'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

const Index = () => <App />
const About = () => <h2>About</h2>
const Users = () => <h2>Users</h2>

const AppRouter = (store) => (
  <Router>
    <Switch>
      <Route path='/' exact component={Index} />
      <Route path='/about/' component={About} />
      <Route path='/users/' component={Users} />
      <Route path='/login/' component={LoginScreen} />
    </Switch>
  </Router>
)

export default AppRouter
