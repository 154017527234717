import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Http from '@ftrm/generic-http-service'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    cursor: 'pointer'
  },
  createButton: {
    position: 'fixed',
    right: theme.spacing.unit * 2,
    bottom: theme.spacing.unit * 2
  }
})

@inject('store')
@observer
class UserList extends Component {
  constructor (props) {
    super(props)

    this._http = new Http(process.env.NODE_ENV === 'production' ? 'https://api.relocation.handelskammarenmalardalen.se' : 'https://api.relocation.futurum.digital')
  }

  async componentWillMount () {
    await this.updateCityList(this.props.store)
    await this.updateUserList(this.props.store)
  }

  updateCityList = async (store) => {
    this.props.store.generic.set('isLoading', true)
    let response = await this._http.get('/v1/cities', store.user.token)

    if (response.data && Array.isArray(response.data)) {
      const cityList = []
      response.data.forEach((cityData) => {
        cityList.push({
          name: cityData.name,
          email: cityData.email,
          admin: cityData.admin,
          organisation: cityData.organisation ? cityData.organisation.name : '',
          organisationId: cityData.organisation ? cityData.organisation._id : '',
          _id: cityData._id,
          activated: cityData.activated,
          city: cityData.city
        })
      })

      store.cityList.set(cityList)
    }
    this.props.store.generic.set('isLoading', false)
  }

  updateUserList = async (store) => {
    store.generic.set('isLoading', true)
    const response = await this._http.get('/v1/users', store.user.token)

    if (response.data && Array.isArray(response.data)) {
      const userList = []
      response.data.forEach((userData) => {
        userList.push({
          name: userData.name,
          email: userData.email,
          admin: userData.admin,
          organisation: userData.organisation ? userData.organisation.name : '',
          organisationId: userData.organisation ? userData.organisation._id : '',
          _id: userData._id,
          activated: userData.activated,
          city: userData.city
        })
      })

      store.userList.set(userList)
    }
    store.generic.set('isLoading', false)
  }

  createNew = (store) => {
    store.userForm.resetUser()
    store.generic.set('showUserForm', true)
  }

  render () {
    const { store, classes } = this.props
    const { userList } = store.userList
    const { showUserForm } = store.generic

    return (
      <div>
        <Typography className={classes.title} variant='h6' color='inherit' noWrap>
          Users
        </Typography>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Activated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                userList.map((user, index) => (
                  <TableRow className={classes.tableRow} key={index} onClick={() => {
                    store.userForm.setUser({
                      name: user.name,
                      admin: user.admin,
                      email: user.email,
                      organisation: user.organisation,
                      organisationId: user.organisationId,
                      _id: user._id,
                      city: user.city
                    })
                    store.generic.set('showUserForm', true)
                    console.log(showUserForm)
                  }}>
                    <TableCell component='th' scope='row'>{user.name}</TableCell>
                    <TableCell component='th' scope='row'>{user.email}</TableCell>
                    <TableCell component='th' scope='row'>{user.organisation}</TableCell>
                    <TableCell component='th' scope='row'>{user.activated ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Paper>
        <Fab color='primary' className={classes.createButton} aria-label='add' onClick={() => this.createNew(store)}>
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

export default withStyles(styles)(UserList)
